.paymentMethods {
    margin-bottom: 1rem;
}

.purchaseBox h6 {
    margin: 0.5rem 0;
}

.btn-centhost {
    background-color: #007bff;
    color: #fff;
    border: none;
}

.btn-centhost:hover {
    background-color: #0056b3;
    color: #fff;
}

.fw-600 {
    font-weight: 600;
}   
