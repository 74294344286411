.header {
    background-color: #fff;
    box-shadow: 0 2px 20px rgba(1,41,112,.1);
    height: 60px;
    padding-left: 20px;
    transition: all .5s;
    z-index: 997;
}


.logo {
    line-height: 1;
}

@media (min-width: 1200px) {
    .logo {
        width: 180px;
    }
}
.logo{
    font-weight: 16px;
    text-decoration: none;
}
.logo img {
    margin-right: 6px;
}
#nav-dropdown{
    font-size: 14px;
    color: black;
}
#nav-dropdown:hover{
    color: #2f57a4;
}
.dropdown-link{
    color: black;
    text-decoration: none;
}

.logo span {
    font-size: 25px;
    font-weight: 550;
    color: #2f57a4;
    text-decoration: none;
}

.header .toggle-sidebar-btn {
    font-size: 25px;
    padding-left: 10px;
    cursor: pointer;
    color: #2f57a4;
}

.header .search-bar {
    min-width: 360px;
    padding: 0 20px;
}

@media (max-width: 1199px) {
    .header .search-bar {
        position: fixed;
        top: 50px;
        left: 0;
        right: 0;
        padding: 20px;
        box-shadow: 0px 0px 15px 0px rgba(1, 41, 112, 0.1);
        background: white;
        z-index: 9999;
        transition: 0.3s;
        visibility: hidden;
        opacity: 0;
    }
    .header .search-bar-show {
        top: 60px;
        visibility: visible;
        opacity: 1;
    }
}

.header .search-form {
    width: 100%;
}

.header .search-form input {
    border: 0;
    font-size: 14px;
    color: #2f57a4;
    border: 1px solid rgba(1, 41, 112, 0.2);
    padding: 7px 38px 7px 8px;
    border-radius: 3px;
    transition: 0.3s;
    width: 100%;
}

.header .search-form input:focus,
.header .search-form input:hover {
    outline: none;
    box-shadow: 0 0 10px 0 rgba(1, 41, 112, 0.15);
    border: 1px solid rgba(1, 41, 112, 0.3);
}

.header .search-form button {
    border: 0;
    padding: 0;
    margin-left: -30px;
    background: none;
}

.header .search-form button i {
    color: #2f57a4;
}



.header-nav ul {
    list-style: none;
}

.header-nav>ul {
    margin: 0;
    padding: 0;
}

.header-nav .nav-icon {
    font-size: 20px;
    color: #2f57a4;
}

.header-nav .nav-profile {
    color: #2f57a4;
}


.header-nav .nav-profile img {
    max-height: 36px;
}

.header-nav .nav-profile span {
    font-size: 14px;
    font-weight: 600;
}

.header-nav .badge-number {
    position: absolute;
    inset: 4px 6px auto auto;
    font-weight: normal;
    font-size: 11px;
    padding: 3px 6px;
}

.header-nav .notifications .notification-item {
    display: flex;
    align-items: center;
    padding: 15px 10px;
    transition: 0.3s;
}

.header-nav .notifications .notification-item i {
    margin: 0 20px 0 10px;
    font-size: 24px;
}

.header-nav .notifications .notification-item h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}

.header-nav .notifications .notification-item p {
    font-size: 13px;
    margin-bottom: 3px;
    color: #919191;
}

.header-nav .notifications .notification-item:hover {
    background-color: #f6f9ff;
}

.header-nav .messages .message-item {
    padding: 15px 10px;
    transition: 0.3s;
}

.header-nav .messages .message-item a {
    display: flex;
}

.header-nav .messages .message-item img {
    margin: 0 20px 0 10px;
    max-height: 40px;
}

.header-nav .messages .message-item h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #444444;
}

.header-nav .messages .message-item p {
    font-size: 13px;
    margin-bottom: 3px;
    color: #919191;
}

.header-nav .messages .message-item:hover {
    background-color: #f6f9ff;
}

.header-nav .profile {
    min-width: 240px;
    padding-bottom: 0;
}

.header-nav .profile .dropdown-header h6 {
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 600;
    color: #444444;
}

.header-nav .profile .dropdown-header span {
    font-size: 14px;
}

.header-nav .profile .dropdown-item {
    font-size: 14px;
    padding: 10px 15px;
    transition: 0.3s;
}

.header-nav .profile .dropdown-item i {
    margin-right: 10px;
    font-size: 18px;
    line-height: 0;
}

.header-nav .profile .dropdown-item:hover {
    background-color: #f6f9ff;
}



.sidebar {
    position: fixed;
    top: 60px;
    left: 0;
    bottom: 0;
    width: 200px;
    z-index: 996;
    transition: all 0.3s;
    padding: 20px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #aab7cf transparent;
    box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
    background-color: #fff;
}

@media (max-width: 1199px) {
    .sidebar {
        left: -300px;
    }
}

.sidebar::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #fff;
}

.sidebar::-webkit-scrollbar-thumb {
    background-color: #aab7cf;
}

@media (min-width: 1200px) {
    #main
    {
        margin-left: 200px;
    }
}

@media (max-width: 1199px) {
    .toggle-sidebar .sidebar {
        left: 0;
    }
}

@media (min-width: 1200px) {
    .toggle-sidebar #main,
    .toggle-sidebar #footer {
        margin-left: 0;
    }
    .toggle-sidebar .sidebar {
        left: -300px;
    }
}

.sidebar-nav {
    padding: 0;
    margin: 0;
    list-style: none;
}

.sidebar-nav li {
    padding: 0;
    margin: 0;
    list-style: none;
}

.sidebar-nav .nav-item {
    margin-bottom: 5px;
}

.sidebar-nav .nav-heading {
    font-size: 11px;
    text-transform: uppercase;
    color: #899bbd;
    font-weight: 600;
    margin: 10px 0 5px 15px;
}

.sidebar-nav .nav-link {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    color: #4154f1;
    transition: 0.3;
    /* background: #f6f9ff; */
    padding: 10px 15px;
    border-radius: 4px;
}

li.dropdown:last-child .dropdown-menu {
    right: 0;
    left: auto;
  }
  
.sidebar-nav .nav-link i {
    font-size: 16px;
    margin-right: 10px;
    color: #2f57a4;
}

.sidebar-nav .nav-link.collapsed {
    color: #444;
    font-size: 13px;
    /* background: #fff; */
}

.sidebar-nav .nav-link.collapsed i {
    color: #899bbd;
}

.sidebar-nav .nav-link:hover {
    color: #fff !important;
    background: #8f97a766;
}

.sidebar-nav .nav-link:hover i {
    color: #8b919d;
}

.sidebar-nav .nav-link .bi-chevron-down {
    margin-right: 0;
    transition: transform 0.2s ease-in-out;
}

.sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
    transform: rotate(180deg);
}

.sidebar-nav .nav-content {
    padding: 5px 0 0 0;
    margin: 0;
    list-style: none;
}

.sidebar-nav .nav-content a {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #2f57a4;
    transition: 0.3;
    padding: 10px 0 10px 40px;
    transition: 0.3s;
}

.sidebar-nav .nav-content a i {
    font-size: 6px;
    margin-right: 8px;
    line-height: 0;
    border-radius: 50%;
}

.sidebar-nav .nav-content a:hover,
.sidebar-nav .nav-content a.active {
    color: #4154f1;
}

.sidebar-nav .nav-content a.active i {
    background-color: #4154f1;
}
.usernameNav{
    /* color: #444 !important; */
    font-weight: bold;
}
#userDropDown{
    margin-right: 20px !important;
    margin-top: 15px;
    padding: 0px;
    font-size: 14px;

}
.basketItems{
}

#basketDropDown{
    margin-left: -150px !important;
    margin-top: 15px;
    padding: 0px;
    font-size: 14px;
    width: 250px;

}
.dropdown-toggle{
    color: #2f57a4;
}
.dropdown-toggle::after{
content: none !important;
}
.avatar{
    width: 35px;
    height: 35px;
}
.dropdown-item{
    border-bottom: 1px solid grey;
}
