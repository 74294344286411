.nav-tabs .nav-item img{
    height: 100px !important;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: transparent !important;
    border: 1px solid white;
}
.gameServerPackage {
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: border 0.3s ease;
}

.selectedPackage {
    border: 2px solid #007bff; 
    background-color: #f0f8ff57; 
}
.gameServerPackage  p{
    margin: 0;
    text-align: center;
}