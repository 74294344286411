.serverBox {
    border: 1px solid #2f57a4;

}

.serverStatBox {
    transition: all ease-in .15s;
    background: var(--bg-primary);
    height: 120px;
    padding: 24px;
    border-radius: 0;
    box-shadow: none;
    border: 1px solid #2f57a4;

    color: #1F2939;
}

.serverStatBox:hover {
    box-shadow: 0 8px 16px 0px rgb(10 14 29 / 4%), 0px 8px 64px 0px rgb(10 14 29 / 8%);
    border-color: transparent;
}

.serverStatBox_title {
    display: flex;
    color: #8692b0;
    margin-bottom: 8px;
}

.serverStatBox_content {
    font-weight: 400;
    display: inline-block;
    font-size: 36px;
    line-height: 36px;
    color: #2f57a4;
}

.serverStatBox_content span {
    color: #8692b0;
    font-weight: 300;
}

.responsiveTableHeading {
    /* border: 1px solid lightgray; */
    border-bottom: 0px;
}

.responsiveTableHeading div {
    padding: 10px;

}

.responsiveTableHeading span {
    font-weight: 600;

}
[data-cc-theme*="dark"] .serverRow:hover{
    background-color: #1F2939 !important;
}

.serverRow:nth-child(1) {
    border-top: 1px solid lightgray;
}

.serverRow {
    border-bottom: 1px solid lightgrey;
    padding: 10px;
    text-decoration: none;
    box-sizing: border-box;

}

.statusText {
    font-size: 12px;
    margin: 0px;
}

.actionMenuItem {
    display: block;
}

.serverRow a {
    text-decoration: none !important;
    /* color: #444; */
    display: contents;

}

@media only screen and (max-width: 767px) {

    .contentsPadding {
        padding: 5px;
    }
}

.serverRow:hover {
    background-color: aliceblue;
}

