div.staffMessage.row {
    border-left: 5px solid #2f57a4 !important;
}

.ticketMessage:hover {
    /* border: 1px solid gray !important; */
    background-color: aliceblue;
}

.ticketMessage img {
    height: 35px !important;
    margin: 5px;
}

[data-cc-theme*="dark"] div.ticketContent {
    color: white !important;
}

[data-cc-theme*="dark"] .ticketMessage:hover {
    background-color: #ffffff00 !important;
}