.pagination {
    display: inline-block;
  }
  
  button {
    font-size: 12px;
    color: white;
    float: left;
    padding: 4px 8px;
    text-decoration: none;
    transition: background-color .3s;
    border: 1px solid #ddd;
    margin: 0 1px;
    background-color: #2f57a4;
    border-radius: 5px;
  }

  button:hover{
    opacity: .9;
  }
 
