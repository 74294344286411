.nav-tabs .nav-item img {
    height: 100px !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    background-color: transparent !important;
    border: 1px solid white;
}

[data-cc-theme*="dark"] .hostingPackage {
    color: white !important;
}

.hostingPackage {
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: border 0.3s ease;
}

.hostingPackage.selectedPackage {
    border: 2px solid #007bff;
    background-color: #f0f8ff57;
}

.hostingPackage {
    border: 1px solid #ddd;
    transition: transform 0.2s, box-shadow 0.2s;
    cursor: pointer;
}

.hostingPackage:hover {
    transform: scale(1.03);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}