.loginBox{
    /* background-color: #fff; */
    border: none;
    border-radius: 5px;
    box-shadow: 0 0 30px rgb(1 41 112 / 10%);
}
.loginBox img{
    height: 300px;
}
.loginRow {
    /* margin-top: -100px; */
}
.loginLogo {
    height: 125px !important;
}