.bootOption {
    padding: 10px;
    font-size: 16px;
    border-radius: 5%;
}

.serverStatBox {
    border-radius: 5px;
    background-color: #2f57a4;
}

.serverStatBox p:not(.text-success, .text-danger, .text-warning, .text-info) {
    color: white !important;

}

.serverStatBox p {
    padding: 0px;
    margin: 0px;
    display: inline !important;

}

.serversMenu li a {
    display: inline !important;
    text-decoration: none;
    color: #2f57a4;
}

[data-cc-theme*="dark"]  .serversMenu li a {
    display: inline !important;
    text-decoration: none;
    color: white;
}

.serversMenu li a i {
    color: white !important;
    display: inline !important;
}

@media only screen and (max-width: 989px) {
    .serversMenu li {
        display: inline-block !important;
    }

    .serversMenu li a {
        padding: 15px !important;
    }
}
.backup-block {
    border-radius: 8px;
    border: 1px solid #2f57a4;
    color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
}

.backup-block:hover {
    transform: translateY(-3px);
}

.backup-block h6 {
    font-size: 0.9rem;
    font-weight: bold;
    margin-bottom: 0.3rem;
}

.backup-block p {
    font-size: 0.85rem;
    margin-bottom: 1rem;
}

.backup-block .btn {
    background-color: #007bff;
    border-color: #007bff;
}

.backup-block .btn:hover {
    background-color: #0056b3;
    border-color: #0056b3;
}
