.refreshTickets {
    color: #899bdd;
    font-size: 14px;
    transition: .3s;
}

.refreshTickets:hover {
    cursor: pointer;
    color: #2f57a4;
}

.shift {
    font-size: 16px;
    color: #899bdd;
}

[data-cc-theme*="dark"] small {
    color: white !important;
}

.importantLink p {
    text-decoration: none;
    background-color: #2f57a4;
    text-align: center;
    cursor: pointer;
    color: white;
    border-radius: 2.5px;
    padding: 15px;
    font-size: 16px;
    margin: 2px;
    font-weight: bold;
}

.importantLink:hover {
    opacity: .9;
}

.taskList {
    list-style-type: none;
    padding: 0px;
}

.task {
    border: 1px solid rgba(0, 0, 0, .125);
    padding: 5px;
    border-radius: 5px;
    margin-top: 5px;
    border-right: 2px solid #2f57a4;

}

.searchTicket {
    position: absolute;
    font-size: 13px;
    padding: 5px;
    right: 0;
    width: 235px;
    border-radius: 5px;
    border: 1px solid lightgray;
}

.taskInfo p {
    padding: 0;
    margin: 0;
}

.taskInfo .text-gray {
    color: gray;
    font-size: 10px;

}

.taskCheckbox {
    width: 20px;
    height: 20px;
    color: #2f57a4;

}

.taskCheckbox:checked {
    accent-color: lightgreen;
}

.activeMops {
    padding: 0px;
    font-size: 12px;
    list-style-type: none;
}

.activeMop {
    border: 1px solid rgba(0, 0, 0, .125);
    padding: 5px;
    border-radius: 5px;
    margin-top: 5px;
    border-right: 2px solid #2f57a4;

}

.selectActiveMopFilter {
    margin-left: 10px;
}

body {
    background-color: #f4f7ff;
}