.table-hover tbody tr:hover {
    background-color: #f5f5f5;
}

.table-active {
    background-color: #e9ecef !important;
}

.osFamilyCard {
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    transition: all 0.2s ease;
    cursor: pointer;
}

.osFamilyItem {
    padding: 1rem;
    text-align: center;
}

.osFamilyImage {
    max-width: 50px;
    margin-bottom: 1rem;
}

.flagLocation {
    max-width: 100%;
    border-radius: 0.25rem;
}

.serverLocationItem {
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    transition: all 0.2s ease;
    cursor: pointer;
}


.packageCards .card {
    border: 1px solid #ddd;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    cursor: pointer;
}

.packageCards .card:hover {
    transform: scale(1.05);
}

.packageCards .selectedPackage .card {
    border: 2px solid #007bff;
}

.packageCards .card-body ul {
    padding: 0;
    margin: 0;
}

.packageCards .card .selectedPackage {
    border: 2px solid #007bff;
}

.packageCards .card-body ul li {
    margin-bottom: 5px;
    font-size: 0.95rem;
}

.packageCards .price {
    font-size: 1.25rem;
    font-weight: bold;
}